<template>
  <div
    id="app"
    class="app-container">
    <StandaloneView v-if="isStandaloneView"/>
    <AppView v-else-if="isMobileView"/>
    <WebView v-else/>
  </div>
</template>
<script>
import { EmbedMixin } from 'global-mixin';
import {
  _isNil,
  alertLocalStorageUtil,
  AlertSubscriptionClient,
  checkUserLoginCookie,
  EventBus
} from 'global-utils';
import AppView from './AppView';
import WebView from './WebView';
import StandaloneView from './StandaloneView';
import {
  getTrackingScripts,
  getOneTrustTrackingScript,
  getCanonicalUrl,
  getMailChimpHubSpotPopupScript
} from 'global-components';
import { mapMetadata } from 'global-metadata';

export default {
  name: 'app',
  components: {
    AppView,
    WebView,
    StandaloneView
  },
  mixins: [EmbedMixin],
  provide() {
    return {
      isMobileView: this.isMobileView,
      isStandaloneView: this.isStandaloneView,
    };
  },
  data() {
    return {
      showNorkonTicker: false,
      showVerifyUserEmail: false,
      serverName: this.$store.state.serverName,
      publicationName: this.$store.state.publication.name,
      isUserLoggedIn: undefined,
      isMobileView: !this.$route.path.startsWith('/standalone/') && ((this.$route.params.view && this.$route.params.view.toLowerCase() === 'zw1izwq') || (this.$route.query.view && this.$route.query.view.toLowerCase() === 'zw1izwq')),
      isStandaloneView: this.$route.path.startsWith('/standalone/'),
      userTheme: 'light-theme',
    };
  },
  mounted() {
    if (!this.isStandaloneView) {
      // EventBus.$emit('sync:alerts', this);
      this.syncAlerts();
    }
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    this.setTheme(initUserTheme);
    setTimeout(() => {
      document.body.classList.add('load-successful');
    }, 3 * 1000);
  },
  methods: {
    getTheme() {
      if (this.$store.state.publication.name === 'hydrogen') {
        return 'dark-theme';
      } else {
        return localStorage.getItem('user-theme');
      }
    },
    setTheme(theme) {
      localStorage.setItem('user-theme', theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },
    getMediaPreference() {
      const hasDarkPreference = window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (hasDarkPreference || this.$store.state.publication.name === 'hydrogen') {
        return 'dark-theme';
      } else {
        return 'light-theme';
      }
    },
    toggleTheme() {
      const activeTheme = localStorage.getItem('user-theme');
      if (activeTheme === 'light-theme') {
        this.setTheme('dark-theme');
      } else {
        this.setTheme('light-theme');
      }
    },
    async syncAlerts() {
      if (checkUserLoginCookie(this)) {
        const subscriptionData = await alertLocalStorageUtil.getItem();
        if (!subscriptionData || (
          _isNil(subscriptionData.topics) &&
          _isNil(subscriptionData.authors) &&
          _isNil(subscriptionData.searchQueries)
        )) {
          const alertSubscriptionClient = new AlertSubscriptionClient(this);
          try {
            await alertSubscriptionClient.fetch();
          } catch (e) {
            console.error(e);
            EventBus.$emit('alert:refresh');
          }
        }
      }
    }
  },
  embed(head, body) {
    const addMeta = head.meta.bind(head);
    mapMetadata(this.$store.state.page.meta).forEach(addMeta);
    head.add(getCanonicalUrl(this));
    if (!this.$route.path.match('/standalone/footer')) {
      head.add(getTrackingScripts(this));
    } else {
      head.add(getOneTrustTrackingScript(this));
    }
    const pubName = this.$store.state.publication.name;
    head
      .link(this.$cdn.resource(`gfx/${pubName}/favicon.ico`), { rel: 'shortcut icon' })
      .style(this.$cdn.asset('global.css'))
      .style(this.$cdn.asset(`${pubName}.css`));
    if (devMode) {
      /* support hot reload for vue-loader styles */
      body
        .style(this.$cdn.asset('global.css'))
        .style(this.$cdn.asset(`${pubName}.css`));
    }
    body.script(this.$cdn.resource(`lib/picturefill.min_3.0.2.js`), { async: true, defer: true });
    if (this.$pubCtx.enableMailchimpHubspotPopup) {
      head.add(getMailChimpHubSpotPopupScript(this.$pubCtx.code));
    }
  }
};
</script>
